@import "tailwind.min.css";

.main-page {
    width: 960px;
    max-width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px 0;
}

.countdown-wrapper {
    font-size: 36px;
}

.main-bg {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.intersocio-brand {
    background-color: white;
    padding: 20px;
}


.intersocio-brand .logos{
    height: 60px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}
